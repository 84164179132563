<template>
  <div class="work">
    <header>
      <i class="iconfont icondenglu" @click="backToHome"></i>
    </header>
    <div class="content">
      <div class="logo">
        <img src="@/assets/image/logo-mini.png" alt="" />
      </div>
      <div class="customerInfo">
        <p class="text">当前客户</p>
        <div class="order">
          <div class="left">
            <h2>嘉汇城1栋101</h2>
            <p>2021年1月14日10:49:09 （近7天打开）</p>
          </div>
          <div class="right">
            <van-button type="info" size="small">更换</van-button>
          </div>
        </div>
      </div>
      <div class="barcode">
        <div>
          <span>板件条码</span>
          <van-field v-model="barcode" clearable />
        </div>
      </div>
      <div class="btnWrapper">
        <van-button type="info">识别手工输入条码</van-button>
        <van-button type="info" @click="$router.push({ name: 'scanPage' })"
          >扫描板件编码</van-button
        >
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// import jsQR from "jsqr";
export default {
  data() {
    return {
      barcode: "",
    };
  },
  mounted() {
    // console.log(jsQR);
    // let imgData =
    //   "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.haichaninfo.com%2Fapi%2Fqrcode.png.php%3Fauth%3Dhttp%3A%2F%2Fwww.haichaninfo.com%2Fxinwen%2Fshow-8401.html&refer=http%3A%2F%2Fwww.haichaninfo.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1613874939&t=77661212a706081c01474c919bfd261a";
    // var code = jsQR(imgData, 215, 215);
    // console.log(code);
  },
  methods: {
    //返回首页
    backToHome: function () {
      this.$dialog
        .confirm({
          title: "温馨提示！",
          message: "是否返回到首页？",
        })
        .then(() => {
          this.$router.go(-2);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang='less' scoped>
.work {
  color: #ffffff;
  height: 100%;
  background-color: #231f1e;
  overflow: hidden;
  box-sizing: border-box;
  header {
    display: flex;
    flex-direction: row-reverse;
    i {
      font-size: 40px;
      padding: 20px 20px 20px 30px;
      color: #03a9f4;
    }
  }
  .content {
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
    overflow: hidden;
    .logo {
      display: flex;
      justify-content: center;
      padding: 20px 0 50px;
    }
    .customerInfo {
      .text {
        font-size: 28px;
        line-height: 50px;
        border-bottom: 1px solid #f2f2f2;
      }
      .order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 35px 0;
        h2 {
          font-size: 32px;
          margin-bottom: 8px;
        }
        p {
          font-size: 25px;
        }
      }
    }
    .barcode {
      padding: 40px 3% 10px;
      > div {
        display: flex;
        border: 1px solid #ffffff;
        span {
          font-size: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
        }
        .van-cell {
          flex: 1;
        }
      }
    }
    .btnWrapper {
      padding: 0 3%;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      button:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
